<script setup lang="ts">
// Properties and events.
//
const props = defineProps<{
  visible: boolean
  baseStyle: string
  text: string
}>()
// Main variables.
//
const text = toRef(props, 'text')
const visible = toRef(props, 'visible')
const baseStyle = toRef(props, 'baseStyle')
// Functions.
//
const styleEditor = function styleEditor(style: string) {
  let output = ''
  switch (style) {
    case 'primary':
      output = 'alert-primary'
      break
    case 'secondary':
      output = 'alert-secondary'
      break
    case 'success':
      output = 'alert-success'
      break
    case 'danger':
      output = 'alert-danger'
      break
    case 'warning':
      output = 'alert-warning'
      break
    case 'info':
      output = 'alert-info'
      break
  }
  return output
}

const style = baseStyle.value
  ? ref(styleEditor(baseStyle.value))
  : ref('alert-secondary')
</script>
<template>
  <div v-if="visible" class="rk-alert alert mb-3" :class="[style]" role="alert">
    <span class="text-center">
      <b>{{ text }}</b>
    </span>
  </div>
</template>

<style lang="scss"></style>
